/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  $stateProvider
    .state('points', {
      url: '/points',
      parent: 'root',
      templateUrl: 'sections/points/templates/acp-points.ng.html',
      controller: 'AcpPointsSectionCtrl',
      data: {
        permissions: {
          only: 'is_points_eligible'
        }
      },
      resolve: {
        pageTitle: /* @ngInject */ function(
          $injector,
          module,
          $q,
          acpPointsModel
        ) {
          return $q
            .all({
              isExternalRedemption: acpPointsModel.isExternalRedemption(),
              displayName: acpPointsModel.getDisplayName()
            })
            .then(function(data) {
              var ymlKey = data.isExternalRedemption
                ? 'external-redemption-page-title'
                : 'page-title';
              return $injector
                .invoke(nsUtilProvider.getPageTitle('sections/points', ymlKey))
                .then(function(pageTitle) {
                  return pageTitle.replace(/\$DISPLAY_NAME/g, data.displayName);
                });
            });
        },
        module: /* @ngInject */ function($ocLazyLoad) {
          return import(/* webpackChunkName: "acp.section.points" */ 'sections/points').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('points.enrollment', {
      template:
        '<div class="acp-points"><acp-points-enrollment terms-url="$resolve.termsUrl"></acp-points-enrollment></div>',
      url: '/enrollment',
      data: {
        permissions: {
          except: ['is_points_enrolled', 'isExternalRedemptionLoyaltyProgram'],
          redirectTo: {
            is_points_enrolled: 'points.dashboard',
            isExternalRedemptionLoyaltyProgram: 'points.external.enrollment'
          }
        }
      },
      resolve: {
        termsUrl: function(acpTermsModel) {
          return acpTermsModel.getLoyaltyPoints();
        }
      }
    })
    .state('points.dashboard', {
      template:
        '<div class="acp-points">' +
        '<acp-points-dashboard redemption-options="$resolve.redemptionOptions" ' +
        'points-balance="$resolve.pointsBalance">' +
        '</acp-points-dashboard></div>',
      data: {
        permissions: {
          only: 'is_points_enrolled',
          except: 'isExternalRedemptionLoyaltyProgram',
          redirectTo: {
            isExternalRedemptionLoyaltyProgram: 'points.external.dashboard'
          }
        }
      },
      resolve: {
        redemptionOptions: function(acpPointsModel) {
          return acpPointsModel.getRedemptionOptions();
        },
        pointsBalance: function(acpPointsModel) {
          return acpPointsModel.getPointsBalance().then(function(data) {
            return data._available_balance;
          });
        }
      }
    })
    .state('points.external', {
      template: '<ui-view></ui-view>',
      url: '/external',
      data: {
        permissions: {
          only: 'isExternalRedemptionLoyaltyProgram'
        }
      }
    })
    .state('points.external.enrollment', {
      template:
        '<div class="acp-points no-padding"><acp-points-external-enrollment></acp-points-external-enrollment></div>',
      data: {
        permissions: {
          except: 'is_points_enrolled',
          redirectTo: {
            is_points_enrolled: 'points.external.dashboard'
          }
        }
      }
    })
    .state('points.external.dashboard', {
      template:
        '<div class="acp-points no-padding"><acp-points-external-dashboard></acp-points-external-dashboard></div>',
      data: {
        permissions: {
          only: ['isExternalRedemptionLoyaltyProgram', 'is_points_enrolled']
        }
      }
    });
}

export default states;
